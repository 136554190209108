export default {
  clientLogin: {
    url: () => "/Clients/login",
    method: "POST",
  },
  clientLogout: {
    url: () => "/Clients/logout",
    method: "POST",
  },
  generatePasscode: {
    url: (userId) => `/Clients/${userId}/generate-passcode`,
    method: "GET",
  },
  getAppData: {
    url: (userId) => `/Clients/${userId}/data`,
    method: "GET",
  },
  getPlaylists: {
    url: (userId) => `/Clients/${userId}/playlists`,
    method: "GET",
  },
  getPlaylist: {
    url: (userId, playlistId) => `/Clients/${userId}/playlists/${playlistId}`,
    method: "GET",
  },
  createPlaylist: {
    url: (userId) => `/Clients/${userId}/playlists`,
    method: "POST",
  },
  createScreenSize: {
    url: (ownerId) => `/Clients/${ownerId}/screenSize`,
    method: "POST",
  },
  deleteScreenSize: {
    url: (ownerId, screenSizeId) =>
      `/Clients/${ownerId}/screenSize/${screenSizeId}`,
    method: "DELETE",
  },
  createContentGroup: {
    url: (clientId) => `/Clients/${clientId}/contentGroup`,
    method: "POST",
  },
  updateContentGroup: {
    url: (clientId, contentGroupId) =>
      `/Clients/${clientId}/contentGroup/${contentGroupId}`,
    method: "PUT",
  },
  removeContentGroup: {
    url: (clientId, contentGroupId) =>
      `/Clients/${clientId}/contentGroup/${contentGroupId}`,
    method: "DELETE",
  },
  getScreens: {
    url: (userId) => `/Clients/${userId}/screens`,
    method: "GET",
  },
  createScreen: {
    url: (userId) => `/Clients/${userId}/screens`,
    method: "POST",
  },
  updateScreenItem: {
    url: (userId, screenId) => `/Clients/${userId}/screens/${screenId}`,
    method: "PUT",
  },
  createContents: {
    url: (userId) => `/Clients/${userId}/contents`,
    method: "POST",
  },
  getSignedUrl: {
    url: (userId) => `/Clients/${userId}/get-signed-url`,
    method: "POST",
  },
  updateContentFk: {
    url: (userId, contentId) => `/Clients/${userId}/contents/${contentId}`,
    method: "PUT",
  },
  updatePlaylist: {
    url: (playlistId) => `/Playlists/${playlistId}`,
    method: "PUT",
  },
  updatePlaylistContent: {
    url: (playlistId, contentId) =>
      `/Playlists/${playlistId}/contents/rel/${contentId}`,
    method: "PUT",
  },
  deletePlaylist: {
    url: (playlistId) => `/Playlists/${playlistId}`,
    method: "DELETE",
  },
  createPlaylistContent: {
    url: (playlistId) => `/Playlists/${playlistId}/contents`,
    method: "POST",
  },
  addContentToPlaylist: {
    url: (playlistId, contentId) =>
      `/Playlists/${playlistId}/contents/rel/${contentId}`,
    method: "PUT",
  },
  addPlaylistContentMeta: {
    url: (playlistId) => `/Playlists/${playlistId}/contentMeta`,
    method: "POST",
  },
  updatePlaylistContentMeta: {
    url: (playlistId, contentMetaId) =>
      `/Playlists/${playlistId}/contentMeta/${contentMetaId}`,
    method: "PUT",
  },
  deletePlaylistContentMeta: {
    url: (playlistId, contentMetaId) =>
      `/Playlists/${playlistId}/contentMeta/${contentMetaId}`,
    method: "DELETE",
  },
  updateContentMetaOrder: {
    url: (playlistId) => `/Playlists/${playlistId}/contentMeta/reorder`,
    method: "PUT",
  },
  getContentMetaOfPlaylist: {
    url: (playlistId) => `/Playlists/${playlistId}/contentMeta`,
    method: "GET",
  },
  updatePlaylistContentGroup: {
    url: (playlistId, contentGroupId) =>
      `/Playlists/${playlistId}/contentGroups/rel/${contentGroupId}`,
    method: "PUT",
  },
  getPlaylistContentGroups: {
    url: (playlistId) => `/Playlists/${playlistId}/contentGroups`,
    method: "GET",
  },
  addPlaylistToScreen: {
    url: (screenId, playlistId) =>
      `/Screens/${screenId}/playlists/rel/${playlistId}`,
    method: "PUT",
  },
  replacePlaylistToScreen: {
    url: (screenId, playlistId) =>
      `/Screens/${screenId}/playlists/rel/${playlistId}/replace`,
    method: "PUT",
  },
  removePlaylistFromScreen: {
    url: (screenId, playlistId) =>
      `/Screens/${screenId}/playlists/rel/${playlistId}`,
    method: "DELETE",
  },
  updateScreen: {
    url: (screenId) => `/Screens/${screenId}`,
    method: "PUT",
  },
  deleteScreen: {
    url: (screenId) => `/Screens/${screenId}`,
    method: "DELETE",
  },
  updateContent: {
    url: (contentId) => `/Contents/${contentId}`,
    method: "PUT",
  },
  deleteContent: {
    url: (contentId) => `/Contents/${contentId}`,
    method: "DELETE",
  },
  replaceContent: {
    url: (contentId) => `/Contents/${contentId}/replace-content`,
    method: "POST",
  },
  getHeaders: {
    url: () => "/Contents/get-headers",
    method: "POST",
  },
  addContentToContentGroup: {
    url: (contentGroupId) => `/ContentGroups/${contentGroupId}/content`,
    method: "POST",
  },
  removeContentFromContentGroup: {
    url: (contentGroupId, contentId) =>
      `/ContentGroups/${contentGroupId}/content/${contentId}`,
    method: "DELETE",
  },
  getProgrammes: {
    url: (userId) => `/Clients/${userId}/programmes`,
    method: "GET",
  },
  createTheatreProgram: {
    url: (userId) => `/Clients/${userId}/programmes`,
    method: "POST",
  },
  removeTheatreProgram: {
    url: (userId, programmId) => `/Clients/${userId}/programmes/${programmId}`,
    method: "DELETE",
  },
  updateTheatreProgram: {
    url: (userId, programmeId) =>
      `/Clients/${userId}/programmes/${programmeId}`,
    method: "PUT",
  },
  getSessions: {
    url: (userId) => `/Clients/${userId}/sessions`,
    method: "GET",
  },
  getSpeakers: {
    url: (userId) => `/Clients/${userId}/speakers`,
    method: "GET",
  },
  createSession: {
    url: (userId) => `/Clients/${userId}/sessions`,
    method: "POST",
  },
  updateSession: {
    url: (userId, sessionId) => `/Clients/${userId}/sessions/${sessionId}`,
    method: "PUT",
  },
  removeSession: {
    url: (userId, sessionId) => `/Clients/${userId}/sessions/${sessionId}`,
    method: "DELETE",
  },
  createSpeaker: {
    url: (userId) => `/Clients/${userId}/speakers`,
    method: "POST",
  },
  updateSpeaker: {
    url: (userId, speakerId) => `/Clients/${userId}/speakers/${speakerId}`,
    method: "PUT",
  },
  removeSpeaker: {
    url: (userId, speakerId) => `/Clients/${userId}/speakers/${speakerId}`,
    method: "DELETE",
  },
  getDataFeedHistories: {
    url: (userId) => `/Clients/${userId}/dataFeedHistories`,
    method: "GET",
  },
  getDataFeeds: {
    url: (userId) => `/Clients/${userId}/dataFeed`,
    method: "GET",
  },
  createDataFeed: {
    url: (userId) => `/Clients/${userId}/dataFeed`,
    method: "POST",
  },
  updateDataFeed: {
    url: (userId, dataFeedId) => `/Clients/${userId}/dataFeed/${dataFeedId}`,
    method: "PUT",
  },
  deleteDataFeed: {
    url: (userId, dataFeedId) => `/Clients/${userId}/dataFeed/${dataFeedId}`,
    method: "DELETE",
  },
  getDataFeedApis: {
    url: () => `/DataFeeds/dataFeedApis`,
    method: "GET",
  },
  createClient: {
    url: () => `/Clients`,
    method: `POST`,
  },
  getClients: {
    url: (offset, limit) =>
      `/Clients?filter={"where":{},"order":["id DESC"],"limit":${limit},"offset":${offset}}`,
    method: `GET`,
  },
  getClientsCount: {
    url: () => `/Clients/count`,
    method: `GET`,
  },
  updateClient: {
    url: (clientId) => `/Clients/${clientId}`,
    method: `PATCH`,
  },
  deleteClient: {
    url: (clientId) => `/Clients/${clientId}`,
    method: `DELETE`,
  },
};
