import React from 'react';
import PropTypes from 'prop-types';
import styles from './OrientationCustomLand.module.scss';

export default function OrientationCustomLand({ isActive }) {
  const getFillColor = isActive ? styles.activeFillColor : styles.defaultFillColor;
  const getStrokeColor = isActive ? styles.activeStrokeColor : styles.defaultStrokeColor;
  return (
    <svg
      className={styles.mainSvg}
      width="60"
      height="24"
      viewBox="0 0 60 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect className={getStrokeColor} x="0.5" y="0.5" width="59" height="23" />
      <path
        className={getFillColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57 21L52.5 21L52.5 20L56 20L56 16.5L57 16.5L57 21Z"
      />
      <path className={getFillColor} fillRule="evenodd" clipRule="evenodd" d="M3 3H7.5V4H4V7.5H3V3Z" />
    </svg>
  );
}

OrientationCustomLand.propTypes = {
  isActive: PropTypes.bool.isRequired,
};
