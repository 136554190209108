import * as types from './types';
import { createReducer, storage } from '../../utils';

export default createReducer({
  user: {},
  fetching: false,
  isAuthenticated: !!storage.getToken(),
  token: storage.getToken(),
})({
  [types.SET_USER]: (state, action) => ({ ...state, user: action.payload }),
  [types.IS_AUTHENTICATED]: (state, action) => ({ ...state, isAuthenticated: action.payload }),
  [types.FETCH_AUTH_START]: state => ({ ...state, fetching: true }),
  [types.FETCH_AUTH_FINISHED]: state => ({ ...state, fetching: false }),
});
