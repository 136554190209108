import _ from "lodash";
import * as validation from "./validation";
import getScreenOrientationIcon from "./getScreenOrientationIcon";
import adjustElementBySize from "./adjustElementBySize";
import normalizeEditorContentStyle from "./normalizeEditorContentStyle";
import normalizeEditorContentStyleAsString from "./normalizeEditorContentStyleAsString";

export { default as safeGet } from "./safeGet";

export const getContentGroupMediaFiles = (mediaFiles) =>
  mediaFiles.filter(({ type }) => type === "contentGroup");
export const getPlaylistMediaFiles = (mediaFiles) =>
  mediaFiles.filter(({ type }) => type === "playlist");

export const noop = () => {};

export const cleanObject = (object) =>
  Object.keys(object)
    .filter((key) => !!object[key])
    .reduce((acc, key) => ({ ...acc, [key]: object[key] }), {});

export const removeSpaces = (str) => str.replace(/\s+/g, " ").trim();

export const cutText = (text, maxLen = 38) =>
  text.length > maxLen ? `${text.substring(0, maxLen - 3)}...` : text;

const getAcceptFilesTypes = (fileType) => {
  const defaultTypes = ["image/jpeg", "image/png", "image/webp", "video/mp4"];

  const typesMap = {
    multiply: "image/jpeg, image/png, image/webp, video/mp4, text/html",
    image: "image/jpeg, image/png, image/webp",
    video: "video/mp4",
  };

  const result = typesMap[fileType];
  if (!result) {
    return defaultTypes;
  }
  return result;
};

export const isImage = (file) =>
  file.type === "image/jpeg" ||
  file.type === "image/png" ||
  file.type === "image/webp";

const getProgressItemsForMediaLibrary = (
  contentsProgress,
  contentFilter = "image"
) => {
  if (!contentsProgress) {
    return [];
  }

  const isHtmlFilter = contentFilter === "html";

  const progressItems = _.values(contentsProgress)
    .filter((content) => {
      // if we are on the tab MULTIPLE show all progress
      if (contentFilter === "multiply") {
        return true;
      }

      // if we are not on the tab MULTIPLE show unique progress
      // and we have contentType in progressData
      const retrieveUploadedFileType = content.fileProgressData.contentType;
      if (!retrieveUploadedFileType) {
        return true;
      }

      if (isHtmlFilter) {
        return contentFilter === retrieveUploadedFileType;
      }
      return contentFilter === retrieveUploadedFileType;
    })
    .filter((content) => !content.fileProgressData.currContentGroupId)
    .map((content) => {
      if (!content) {
        return null;
      }

      if (content.fileProgressData.flagField !== "content") {
        return null;
      }

      return content;
    });

  return progressItems
    .filter((item) => item !== null)
    .sort(
      (a, b) => a.fileProgressData.uniqueKey > b.fileProgressData.uniqueKey
    );
};

const getProgressItemsForPlaylist = (contentsProgress, playlistId) =>
  !contentsProgress
    ? []
    : _.values(contentsProgress)
        .filter(
          (itemProgress) =>
            itemProgress.fileProgressData.playlistId === playlistId
        )
        .map((content) => {
          if (content) {
            // flag for define displaying progress when uploading content with attach meta
            if (content.fileProgressData.flagField !== "contentToPlaylist") {
              return null;
            }

            return content;
          }
          return null;
        })
        .filter((content) => Boolean(content))
        .sort(
          (a, b) => a.fileProgressData.uniqueKey > b.fileProgressData.uniqueKey
        );

const defineIconOfSizeOrientation = (screenSize) => {
  if (screenSize.default && screenSize.resolutionWidth === 1920)
    return "landscape";
  if (screenSize.default && screenSize.resolutionWidth === 1080)
    return "portrait";
  if (screenSize.resolutionHeight === screenSize.resolutionWidth)
    return "square";
  if (
    !screenSize.default &&
    screenSize.resolutionHeight < screenSize.resolutionWidth
  )
    return "customLandscape";
  if (
    !screenSize.default &&
    screenSize.resolutionWidth < screenSize.resolutionHeight
  )
    return "customPortrait";
  return "landscape";
};

export function getScreenDimensions(width, height) {
  const gcd = function greatestCommonDivisor(a, b) {
    if (!b) {
      return a;
    }
    return gcd(b, a % b);
  };
  const newGcd = gcd(width, height);
  const dimensions = `${width / newGcd}:${height / newGcd}`;
  return dimensions;
}

export const isTimeCorrect = (timeValue) =>
  timeValue ? timeValue.indexOf("_") === -1 : false;

export const getOptions = (optionsJSON) => {
  try {
    return JSON.parse(optionsJSON);
  } catch (e) {
    return {};
  }
};

export {
  validation,
  getAcceptFilesTypes,
  getProgressItemsForMediaLibrary,
  getProgressItemsForPlaylist,
  defineIconOfSizeOrientation,
  getScreenOrientationIcon,
  adjustElementBySize,
  normalizeEditorContentStyle,
  normalizeEditorContentStyleAsString,
};
