import React, { Component } from "react";
import styles from "./ClientsCreateView.module.scss";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { getFormValues } from "redux-form";
import { connect } from "react-redux";
import EntityNameField from "../../../../common/components/UI/EntityNameField";
import { validation } from "../../../../../utils";
import ButtonDigital from "../../../../common/components/UI/ButtonDigital";
import { Button } from "reactstrap";

import onClickOutside from "react-onclickoutside";
import { history } from "../../../../../state/utils";
import Icons from "../../../../common/components/UI/Icons";

import ClientCreateEmailForm from "../ClientCreateEmailForm/";
import ClientCreateAccessCodeForm from "../ClientCreateAccessCodeForm";
import ClientCreateUsernameForm from "../ClientCreateUsernameForm";
import ClientCreatePasswordForm from "../ClientCreatePasswordForm";

class ClientsCreateView extends Component {
  constructor(props) {
    super(props);
    this.refEmail = React.createRef();
    this.refUsername = React.createRef();
    this.refPassword = React.createRef();
    this.refAccessCode = React.createRef();
  }

  handleClickOutside() {
    history.push(`${process.env.PUBLIC_URL}/clients`);
  }

  state = {
    isChangeEmail: false,
    isChangeUsername: false,
    isChangePassword: false,
    isChangeAccessCode: false,
  };

  render() {
    const fetchCreateClient = this.props.fetchCreateClient;

    return (
      <div className={styles.container}>
        <span className={styles.title}>ADDING NEW CLIENT</span>
        <Link to="/clients" className={styles.closeButton} />
        <ClientCreateEmailForm
          onChangeEmail={() => {
            this.setState((state) => ({ ...state, isChangeEmail: true }));
          }}
          refEmail={this.refEmail}
        />
        <ClientCreateUsernameForm
          onChangeUsername={() => {
            this.setState((state) => ({ ...state, isChangeUsername: true }));
          }}
          refUsername={this.refUsername}
        />
        <ClientCreateAccessCodeForm
          defaultAccessCode={
            validation.isEmail(this.props.emailInput)
              ? this.props.emailInput.substring(
                  0,
                  this.props.emailInput.indexOf("@")
                )
              : null
          }
          onChangeAccessCode={() => {
            this.setState((state) => ({ ...state, isChangeAccessCode: true }));
          }}
          refAccessCode={this.refAccessCode}
        />
        <ClientCreatePasswordForm
          onChangePassword={() => {
            this.setState((state) => ({ ...state, isChangePassword: true }));
          }}
          refPassword={this.refPassword}
        />
        <div className={styles.buttonsContainer}>
          <ButtonDigital
            className={styles.addClientButton}
            disable={
              !(
                validation.isEmail(this.props.emailInput) &&
                this.state.isChangePassword
              )
            }
            isConfirmStyle={true}
            type="button"
            onClick={() => {
              fetchCreateClient({
                email: this.props.emailInput,
                username: this.props.usernameInput,
                accessCode: this.props.accessCodeInput,
                password: this.props.passwordInput,
              });

              history.push(`${process.env.PUBLIC_URL}/clients`);
            }}
          >
            Add Client
          </ButtonDigital>
          <Link to={`/clients`} className={styles.cancelButton}>
            <Icons icon="xCircleRed" widthIcons={26} marginRight={5} />
            Cancel
          </Link>
        </div>
      </div>
    );
  }
}

ClientsCreateView.propTypes = {};

const mapStateToProps = (state) => {
  const { username } = getFormValues("createClientUsername")(state) || {};
  const { email } = getFormValues("createClientEmail")(state) || {};
  const { password } = getFormValues("createClientPassword")(state) || {};
  const { accessCode } = getFormValues("createClientAccessCode")(state) || {};
  return {
    usernameInput: username,
    emailInput: email,
    passwordInput: password,
    accessCodeInput: accessCode,
  };
};

export default connect(mapStateToProps)(onClickOutside(ClientsCreateView));
