import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import styles from "./LayoutSidebar.module.scss";
import Icons from "../../common/components/UI/Icons";
import SvgIco from "../../../assets/svg";
import { mixpanel } from "../../../state/utils";

const { SvgIcoCloud, SvgIcoWindows, SvgIcoAppStore } = SvgIco;
const isProduction = process.env.REACT_APP_BUILD === "production";

class LayoutSidebar extends React.Component {
  state = {
    isToggleAppList: false,
  };

  onToggleAppList = () => {
    const { isToggleAppList } = this.state;
    this.setState({ isToggleAppList: !isToggleAppList });
  };

  render() {
    const {
      location: { pathname },
      user,
    } = this.props;
    const { isToggleAppList } = this.state;
    const passCode = user["pass-code"];

    return (
      <div className={styles.container}>
        <Nav vertical className={styles.navClass}>
          <NavItem className={styles.navItemClass} id="clients">
            <NavLink
              id="clients"
              tag={Link}
              className={
                pathname.slice(1, 8) === "clients"
                  ? styles.linkStyleActive
                  : styles.linkStyle
              }
              to="/clients"
            >
              <span className={styles.iconPosition} id="clients">
                <Icons
                  icon={
                    pathname.slice(1, 8) === "clients"
                      ? "clientBlack"
                      : "clientGray"
                  }
                  widthIcons={20}
                  id="clients"
                />
              </span>
              Clients
            </NavLink>
          </NavItem>
        </Nav>
      </div>
    );
  }
}

LayoutSidebar.propTypes = {
  user: PropTypes.object.isRequired,
  setWinToggler: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default withRouter(LayoutSidebar);
