import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

const SvgSplitScreen = ({ className, color, size }) => (
  <svg
    className={cn(className && className)}
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 2L8 2L8 38H32L32 2ZM9 20.5L9 37H31L31 20.5H9ZM9 19.5L9 3L31 3V19.5H9Z"
      fill={color}
    />
  </svg>
);

SvgSplitScreen.defaultProps = {
  color: '#8A8A8F',
  size: 40,
};

SvgSplitScreen.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  className: PropTypes.string,
};

export default SvgSplitScreen;
