import React from "react";
import PropTypes from "prop-types";
import SignInForm from "../SignInForm";
import styles from "./AuthView.module.scss";
import SvgIcons from "../../../../../assets/svg";

const { SvgBrand } = SvgIcons;

const AuthView = ({ handleSignIn, email, password }) => (
  <div className={styles.container}>
    <div className={styles.rightSide}>
      <div className={styles.label}>
        <a
          href="https://eventignite.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <SvgBrand />
        </a>
      </div>
      <SignInForm email={email} password={password} onSubmit={handleSignIn} />
      <div className={styles.footer}>EventIgnite 2019</div>
    </div>
  </div>
);

AuthView.propTypes = {
  handleSignIn: PropTypes.func.isRequired,
  fetching: PropTypes.bool,
  email: PropTypes.string,
  password: PropTypes.string,
};

export default AuthView;
