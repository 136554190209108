import React from 'react';

export default function SvgIcoWindows() {
  return (
    <svg width="30" height="28" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.2002 20.3L0.200195 19V11.4H9.2002V20.3ZM9.2002 10.7H0.200195V3.5L9.2002 2.2V10.7ZM21.5002 22L9.90021 20.3V11.4H21.5002V22ZM21.5002 10.7H9.90021V2.2L21.5002 0.5V10.7Z"
        fill="black"
      />
    </svg>
  );
}
