import React from 'react';
import PropTypes from 'prop-types';
import styles from './OrientationPortrait.module.scss';

export default function OrientationPortrait({ isActive }) {
  const getColor = isActive ? styles.activeColor : styles.defaultColor;
  return (
    <svg
      className={styles.mainSvg}
      width="24"
      height="36"
      viewBox="0 0 24 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={getColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 35.0001L23 1.00012L1 1.00012L1 35.0001L23 35.0001ZM24 0.00012207L1.43051e-06 0.000121021L-1.43099e-07 36.0001L24 36.0001L24 0.00012207Z"
      />
      <path
        className={getColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5 2.50012L21.5 7.00012L20.5 7.00012L20.5 3.50012L17 3.50012L17 2.50012L21.5 2.50012Z"
      />
      <path
        className={getColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 33.4999L2.5 28.9999L3.5 28.9999L3.5 32.4999L7 32.4999L7 33.4999L2.5 33.4999Z"
      />
    </svg>
  );
}

OrientationPortrait.propTypes = {
  isActive: PropTypes.bool.isRequired,
};
