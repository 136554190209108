import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

const SvgTheatreProgramme = ({ className, color, size }) => (
  <svg
    className={cn(className && className)}
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M9 37L9 3L31 3L31 37L9 37ZM8 2L32 2L32 38L8 38L8 2Z" fill={color} />
    <path d="M8.5 11H31.5M8.5 29H31.5" stroke={color} />
  </svg>
);

SvgTheatreProgramme.defaultProps = {
  size: 40,
  color: '#8A8A8F',
};

SvgTheatreProgramme.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
};

export default SvgTheatreProgramme;
