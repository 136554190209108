import { put, call, takeLatest, fork } from "redux-saga/effects";
import * as types from "./types";
import * as actions from "./actions";
import { appActions } from "../app";
import { fetchGetClients, fetchGetClientsCount } from "../clients/actions";

import { storage, fetch, apiRoutes, mixpanel } from "../../utils";

const { showNotification } = appActions;

function* authFlow(action) {
  try {
    const credentials = action.payload;
    const withToken = false;

    const id = credentials.id;
    yield put({ type: types.FETCH_AUTH_START });
    const { url, method } = apiRoutes.clientLogin;
    const res = yield call(fetch, url(), method, credentials, null, {
      withToken,
    });
    yield call(storage.setToken, res);
    yield put(actions.setUser({ name: null, id: res.userId }));
    yield put(actions.changeAuthStatus(true));

    yield put(fetchGetClients());

    mixpanel.identify(res.userId);
    mixpanel.track("Client logged in");
    mixpanel.people.set({
      $email: credentials.email,
    });

    yield put({ type: types.FETCH_AUTH_FINISHED });
  } catch (error) {
    yield put(
      showNotification({
        level: "error",
        autoDismiss: 1,
        message: "The email or password is incorrect",
      })
    );
    yield put({ type: types.FETCH_AUTH_FINISHED });
  }
}

function* logoutFlow() {
  try {
    const { url, method } = apiRoutes.clientLogout;

    yield put(actions.changeAuthStatus(false));
    yield call(fetch, url(), method);
    yield put({ type: types.FETCH_AUTH_FINISHED });

    mixpanel.track("Client logged out");
  } catch (error) {
    yield put({ type: types.FETCH_AUTH_FINISHED });
  } finally {
    yield fork(storage.clearToken);
  }
}

export default [
  takeLatest(types.FETCH_AUTH, authFlow),
  takeLatest(types.LOG_OUT, logoutFlow),
];
