import React from "react";
import { Route, Switch } from "react-router-dom";
import styles from "./ClientsView.module.scss";
import ClientsList from "../ClientsList";
import ClientsCreateView from "../ClientsCreateView/ClientsCreateView";
import ClientsEditView from "../ClientsEditView";
import UploadSpinner from "../../../../common/components/UI/UploadSpinner";

class ClientsView extends React.Component {
  constructor() {
    super();
    this.state = {
      //currentPage: 1,
      recordsPerPage: 14,
      //clientsToRender: [],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    /*if (
      prevProps.clients != this.props.clients ||
      prevState.currentPage != this.state.currentPage
    ) {
      this.setState((state) => ({
        ...state,
        clientsToRender: this.props.clients.slice(
          state.currentPage * state.recordsPerPage - state.recordsPerPage,
          state.currentPage * state.recordsPerPage
        ),
      }));
    }*/

    if (this.props.currentPage != prevProps.currentPage) {
      this.props.fetchGetClients(
        (this.props.currentPage - 1) * this.state.recordsPerPage,
        this.state.recordsPerPage
      );
    }
  }

  buildRoutes = () => {
    const setCurrentPage = (currentPage) => {
      this.props.setCurrentPage(currentPage);
    };

    const renderClientsList = (
      <ClientsList
        //clientsToRender={this.state.clientsToRender}
        clients={this.props.clients}
        recordsPerPage={this.state.recordsPerPage}
        //currentPage={this.state.currentPage}
        //setCurrentPage={setCurrentPage}
        fetchDeleteClient={this.props.fetchDeleteClient}
        openConfirmModal={this.props.openConfirmModal}
        fetchEditClient={this.props.fetchEditClient}
        isGettingClients={this.props.isGettingClients}
        currentLocation={this.props.currentLocation}
        clientsCount={this.props.clientsCount}
        setClientsCount={this.props.setClientsCount}
        currentPage={this.props.currentPage}
        setCurrentPage={this.props.setCurrentPage}
      />
    );

    const routes = [
      {
        path: "/clients",
        render: (props) => renderClientsList,
      },
      {
        path: "/clients/new",
        render: (props) => (
          <ClientsCreateView key="create-view" {...this.props} />
        ),
      },
      {
        path: "/clients/edit/:clientId",
        render: (props) => (
          <ClientsEditView key="create-view" {...this.props} />
        ),
      },
    ];

    return routes;
  };

  render() {
    const { clients } = this.props;

    return clients ? (
      <Switch>
        {this.props.isGettingClients || !this.props.clients ? (
          <UploadSpinner />
        ) : (
          <div className={styles.container}>
            {this.buildRoutes().map(({ path, render }) => (
              <Route key={path} path={path} render={render} />
            ))}
          </div>
        )}
      </Switch>
    ) : (
      <UploadSpinner />
    );
  }
}

ClientsView.propTypes = {};

export default ClientsView;
