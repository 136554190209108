import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './Button.module.scss';

function Button({ children, className, theme, ...rest }) {
  return (
    <button type="button" className={cn(styles.button, styles[theme], className && className)} {...rest}>
      {children}
    </button>
  );
}

Button.defaultProps = {
  theme: 'default',
};

Button.propTypes = {
  theme: PropTypes.oneOf(['invert', 'default']),
  children: PropTypes.any,
};

export default Button;
