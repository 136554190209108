import SvgIcoCloud from './SvgIcoCloud';
import SvgIcoWindows from './SvgIcoWindows';
import SvgIcoAppStore from './SvgIcoAppStore';
import SvgIcoStackFiles from './SvgIcoStackFiles';
import SvgIcoSquare from './SvgIcoSquare';
import SvgIcoNotDefault from './SvgIcoNotDefault';
import SvgIcoOrientation from './SvgIcoOrientation';
import SvgBrand from './SvgBrand';
import SvgClose from './SvgClose';
import SvgEye from './SvgEye';
import OrientationLandscape from './OrientationLandscape';
import OrientationCustomPort from './OrientationCustomPort';
import OrientationCustomLand from './OrientationCustomLand';
import OrientationPortrait from './OrientationPortrait';
import OrientationSquare from './OrientationSquare';
import SvgTheatreProgramme from './SvgTheatreProgramme';
import SvgSplitScreen from './SvgSplitScreen';

export default {
  SvgIcoCloud,
  SvgIcoWindows,
  SvgIcoAppStore,
  SvgIcoStackFiles,
  SvgIcoSquare,
  SvgIcoNotDefault,
  SvgIcoOrientation,
  SvgBrand,
  SvgClose,
  SvgEye,
  OrientationLandscape,
  OrientationCustomPort,
  OrientationCustomLand,
  OrientationPortrait,
  OrientationSquare,
  SvgTheatreProgramme,
  SvgSplitScreen,
};
