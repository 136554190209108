import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import styles from "./SignInForm.module.scss";

const SignInForm = ({ handleSubmit, email, password }) => {
  const isDisableButton = !email || !password;
  return (
    <form className={styles.FormStyle}>
      <div className={styles.title}>Admin Panel</div>
      <div className={styles.nameAndFieldContainer}>
        <label className={styles.fieldName}>E-mail</label>
        <div>
          <Field
            className={styles.field}
            name="email"
            component="input"
            type="email"
            placeholder="Enter E-mail"
          />
        </div>
      </div>
      <div className={styles.nameAndFieldContainer}>
        <label className={styles.fieldName}>Password</label>
        <div>
          <Field
            className={styles.field}
            name="password"
            component="input"
            type="password"
            placeholder="Enter password"
          />
        </div>
      </div>
      <button
        type="submit"
        disabled={isDisableButton}
        className={
          isDisableButton
            ? `${styles.button} ${styles.buttonDisable}`
            : styles.button
        }
        onClick={handleSubmit}
      >
        Login
      </button>
    </form>
  );
};

SignInForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: "signIn",
})(SignInForm);
