import * as types from "./types";
import { createReducer } from "../../utils";

export default createReducer({
  clients: [],
  clientsCount: 0,
  currentPage: 1,
  recordsPerPage: 12,
  isCreatingClient: false,
  isDeletingClient: false,
  isEditingClient: false,
  isGettingClients: false,
})({
  [types.SET_CLIENTS]: (state, action) => ({
    ...state,
    clients: [...action.payload],
  }),
  [types.CREATE_CLIENT_START]: (state, action) => ({
    ...state,
    isCreatingClient: true,
  }),
  [types.CREATE_CLIENT_END]: (state, action) => ({
    ...state,
    isCreatingClient: false,
  }),
  [types.DELETE_CLIENT_START]: (state, action) => ({
    ...state,
    isDeletingClient: true,
  }),
  [types.DELETE_CLIENT_END]: (state, action) => ({
    ...state,
    isDeletingClient: false,
  }),
  [types.EDIT_CLIENT_START]: (state, action) => ({
    ...state,
    isEditingClient: true,
  }),
  [types.EDIT_CLIENT_END]: (state, action) => ({
    ...state,
    isEditingClient: false,
  }),
  [types.GET_CLIENTS_START]: (state, action) => ({
    ...state,
    isGettingClients: true,
  }),
  [types.GET_CLIENTS_END]: (state, action) => ({
    ...state,
    isGettingClients: false,
  }),
  [types.SET_CURRENT_PAGE]: (state, action) => ({
    ...state,
    currentPage: action.payload,
  }),
  [types.SET_CLIENTS_COUNT]: (state, action) => ({
    ...state,
    clientsCount: action.payload,
  }),
});
