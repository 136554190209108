import React from 'react';
import PropTypes from 'prop-types';
import styles from './OrientationLandscape.module.scss';

export default function OrientationLandscape({ isActive }) {
  const getColor = isActive ? styles.activeColor : styles.defaultColor;
  return (
    <svg
      className={styles.mainSvg}
      width="36"
      height="24"
      viewBox="0 0 36 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={getColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35 0.999878H1V22.9999H35V0.999878ZM0 -0.00012207V23.9999H36V-0.00012207H0Z"
      />
      <path
        className={getColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 2.49988H7V3.49988H3.5V6.99988H2.5V2.49988Z"
      />
      <path
        className={getColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.5 21.5001L29 21.5001L29 20.5001L32.5 20.5001L32.5 17.0001L33.5 17.0001L33.5 21.5001Z"
      />
    </svg>
  );
}

OrientationLandscape.propTypes = {
  isActive: PropTypes.bool.isRequired,
};
