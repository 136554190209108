import { showNotification } from '../ducks/app/actions';
import sentryReport from './sentry';
import { getErrorMessage } from '.';

function handleError(error) {
  sentryReport(error);
  const messageError = getErrorMessage(error);
  const { level, message } = messageError;
  handleError.dispatch(showNotification({ level, autoDismiss: 1, message }));
}

export const provideDispatchToHandleError = dispatch => {
  handleError.dispatch = dispatch;
};

export default handleError;
