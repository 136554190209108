import * as types from "./types";
import { createReducer } from "../../utils";

export default createReducer({
  notification: {},
})({
  [types.SHOW_NOTIFICATION]: (state, action) => ({
    ...state,
    notification: action.payload,
  }),
});
