import React, { Component } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import styles from "./EntityNameField.module.scss";
import Icons from "../Icons";
import { cutText } from "../../../../../utils";

class EntityNameField extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  state = {
    isFocus: false,
  };

  componentDidMount() {
    const { isFocusAfterMounted } = this.props;
    if (isFocusAfterMounted) {
      this.setState(
        { isFocus: true },
        this.inputRef.current && this.inputRef.current.focus()
      );
    }
  }

  componentWillUnmount() {
    this.setState({ isFocus: false });
  }

  handleOnSetFocus = () => {
    this.setState(
      { isFocus: true },
      this.inputRef.current && this.inputRef.current.focus()
    );
  };

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.setState({ isFocus: false });
    }
  };

  render() {
    const {
      className,
      placeholder,
      inputClassName,
      isAddScreen,
      input,
      type,
      isEditable,
      isNoWrap,
      isFocusAfterMounted,
      meta,
      ...rest
    } = this.props;

    const { touched, error, warning } = meta;
    const { isFocus } = this.state;
    const isEmpty = input.value === "";

    const onClick = isEditable ? this.handleOnSetFocus : null;

    return (
      <div className={styles.wrapContainer}>
        <div className={cn(styles.container, className && className)}>
          <div
            className={cn(
              styles.fakeInput,
              isNoWrap && styles.fakeInputIsNoWrap,
              isFocus && styles.visuallyHidden,
              !isEditable && styles.fakeInputIsDisabled
            )}
            onClick={onClick}
          >
            {isEmpty && placeholder && (
              <span className={styles.placeholder}>
                {cutText(placeholder, 199)}
              </span>
            )}
            {!isEmpty && (
              <span
                dangerouslySetInnerHTML={{ __html: cutText(input.value, 199) }}
              />
            )}
          </div>
          <input
            {...input}
            type={type}
            ref={this.inputRef}
            autoComplete="off"
            placeholder={placeholder}
            className={cn(
              styles.input,
              inputClassName && inputClassName,
              !isFocus && styles.visuallyHidden
            )}
            onKeyPress={this.handleKeyPress}
            onBlur={(e) => {
              this.setState({ isFocus: false });
              if (!meta.pristine) {
                input.onBlur(e);
              }
            }}
            {...rest}
          />
        </div>

        <div>
          {touched &&
            (error && <span className={styles.warning}>{error}</span>)}
          {touched &&
            (warning && <span className={styles.warning}>{warning}! </span>)}
        </div>
      </div>
    );
  }
}

EntityNameField.defaultProps = {
  isEditable: true,
  isNoWrap: false,
};

EntityNameField.propTypes = {
  type: PropTypes.string,
  inputRef: PropTypes.any,
  input: PropTypes.object.isRequired,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
    warning: PropTypes.string,
  }),
  isEditable: PropTypes.bool,
  isNoWrap: PropTypes.bool,
};

export default EntityNameField;
