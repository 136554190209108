import React from 'react';

export default function SvgClose() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="white" />
      <path d="M13 13L27 27M27 12.5L13 26.5" stroke="black" strokeLinecap="round" />
    </svg>
  );
}
