import React, { useEffect } from "react";
import { Field, reduxForm, change } from "redux-form";
import PropTypes from "prop-types";
import styles from "./ClientCreateAccessCodeForm.module.scss";
import { validation } from "../../../../../utils";
import EntityNameField from "../../../../common/components/UI/EntityNameField/EntityNameField";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

function ClientCreateAccessCodeForm({
  onChangeAccessCode,
  refAccessCode,
  defaultAccessCode,
  change,
  placeholder,
}) {
  useEffect(() => {
    if (defaultAccessCode) {
      change("accessCode", defaultAccessCode);
    }
  }, [defaultAccessCode]);

  return (
    <form className={styles.theaterNameForm}>
      <Field
        ref={refAccessCode}
        withRef={true}
        name="accessCode"
        type="text"
        isFocusAfterMounted={true}
        component={EntityNameField}
        className={styles.input}
        placeholder={placeholder ? placeholder : "Access code"}
        //validate={[validation.required]}
        onChange={onChangeAccessCode}
        //normalize={validation.normalizeExtraSpaces}
      />
    </form>
  );
}

ClientCreateAccessCodeForm.propTypes = {};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ change }, dispatch);
};

export default reduxForm(
  {
    form: "createClientAccessCode",
    /*enableReinitialize: true,
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: false,*/
  },
  mapDispatchToProps
)(ClientCreateAccessCodeForm);
