import messageExtractor from './message-extractor';

export default function getErrorMessage(errorMessage) {
  const errorResponse = { ...errorMessage };
  if (errorMessage.code === 'AUTHORIZATION_REQUIRED') {
    errorResponse.level = 'success';
    errorResponse.message = 'Your session has expired, please log in again';
    return errorResponse;
  }

  return process.env.NODE_ENV === 'development'
    ? { level: 'error', message: messageExtractor(errorResponse) }
    : {
        level: 'error',
        message:
          errorMessage.name === 'UserError'
            ? messageExtractor(errorResponse)
            : 'Something went wrong. Please, try again later.',
      };
}
