import { all } from "redux-saga/effects";
import { reducer as formReducer } from "redux-form";
import { authSagas, authReducer } from "./auth";
import { appReducer } from "./app";
import { confirmModalReducer } from "./confirmModal";
import { clientsReducer, clientsSagas } from "./clients";

export function* rootSaga() {
  yield all([...authSagas, ...clientsSagas]);
}

export const reducers = {
  app: appReducer,
  auth: authReducer,
  form: formReducer,
  confirmModal: confirmModalReducer,
  clients: clientsReducer,
};
