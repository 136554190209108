import React from "react";
// import PropTypes from 'prop-types';
import cn from "classnames";
import ReactSelect from "react-select";
import styles from "./Pagination.module.scss";
import Icons from "../Icons";
import { useEffect, useState } from "react";
import { event } from "jquery";

function Pagination({ numberOfPages, currentPage, setCurrentPage }) {
  const pageNumbers = [...Array(numberOfPages + 1).keys()].slice(1);

  const [pageInput, setPageInput] = useState(currentPage);
  const [disableLeft, setDisableLeft] = useState(true);
  const [disableRight, setDisableRight] = useState(false);

  useEffect(() => {
    if (currentPage == 1) setDisableLeft(true);
    else if (currentPage == numberOfPages) {
      setDisableRight(true);
      setDisableLeft(false);
    } else {
      setDisableLeft(false);
      setDisableRight(false);
    }
  }, [currentPage]);

  const handlePageInputChange = (event) => {
    let result = event.target.value.replace(/\D/g, "");

    if (result > numberOfPages || result < 0) result = "";

    setPageInput(result);

    //if (result < 0 || result > numberOfPages) setCurrentPage(result);
  };

  const handlePageInputOnBlur = (event) => {
    console.log(event.target.value);
    if (event && event.target && event.target.value)
      setCurrentPage(event.target.value);
  };

  return (
    <>
      <ul className={styles.PaginationList}>
        <li>
          <a
            onClick={() => {
              if (!(+currentPage - 1 <= 0)) {
                setCurrentPage(+currentPage - 1);
                setPageInput(+currentPage - 1);
              }
            }}
          >
            <span
              className={disableLeft ? styles.goLeft__disabled : styles.goLeft}
            >
              {"<"}
            </span>
          </a>
        </li>
        <li>
          <input
            type="text"
            value={pageInput}
            onChange={handlePageInputChange}
            onPointerOut={handlePageInputOnBlur}
          />
          {"/"}
        </li>
        <li>{numberOfPages}</li>
        <li>
          <a
            onClick={() => {
              if (!(+currentPage + 1 > numberOfPages)) {
                setCurrentPage(+currentPage + 1);
                setPageInput(+currentPage + 1);
              }
            }}
          >
            <span
              className={
                disableRight ? styles.goRight__disabled : styles.goRight
              }
            >
              {">"}
            </span>
          </a>
        </li>
      </ul>
    </>
  );
}

export default Pagination;
