import React from 'react';
import PropTypes from 'prop-types';

const SvgIcoSquare = ({ setColor, iconSize }) => (
  <svg width={iconSize} height={iconSize} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="5.5" y="5.5" width="29" height="29" stroke={setColor} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.25 31.25L25.625 31.25L25.625 30L30 30L30 25.625L31.25 25.625L31.25 31.25Z"
      fill={setColor}
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M8.75 8.75H14.375V10H10V14.375H8.75V8.75Z" fill={setColor} />
  </svg>
);

SvgIcoSquare.propTypes = {
  setColor: PropTypes.string.isRequired,
  iconSize: PropTypes.number.isRequired,
};

export default SvgIcoSquare;
