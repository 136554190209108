import React, { useEffect } from "react";
import { Field, reduxForm, change } from "redux-form";
import PropTypes from "prop-types";
import styles from "./ClientCreateUsernameForm.module.scss";
import { validation } from "../../../../../utils";
import EntityNameField from "../../../../common/components/UI/EntityNameField/EntityNameField";
import { bindActionCreators } from "redux";

function ClientCreateUsernameForm({
  onChangeUsername,
  refUsername,
  defaultUsername,
  change,
  placeholder,
}) {
  useEffect(() => {
    if (defaultUsername) {
      change("username", defaultUsername);
    }
  }, [defaultUsername]);

  return (
    <form className={styles.theaterNameForm}>
      <Field
        ref={refUsername}
        withRef={true}
        name="username"
        type="text"
        isFocusAfterMounted={true}
        component={EntityNameField}
        className={styles.input}
        placeholder={placeholder ? placeholder : "Username"}
        //validate={[validation.required, validation.isEmail]}
        onChange={onChangeUsername}
        //normalize={validation.normalizeExtraSpaces}
      />
    </form>
  );
}

ClientCreateUsernameForm.propTypes = {};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ change }, dispatch);
};

export default reduxForm(
  {
    form: "createClientUsername",
    /*enableReinitialize: true,
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: false,*/
  },
  mapDispatchToProps
)(ClientCreateUsernameForm);
