import withAuthentication from "../views/hocs/withAuthentication";
import { Auth, Clients, Home } from "../views/pages";

const routes = [
  {
    path: "/",
    component: withAuthentication(Home),
    exact: true,
  },
  {
    path: "/auth",
    component: withAuthentication(Auth),
    exact: true,
  },
  {
    path: "/clients",
    component: withAuthentication(Clients),
  },
];

export default routes;
