import * as types from './types';

export const fetchAuth = formData => ({
  type: types.FETCH_AUTH,
  payload: formData,
});

export const setUser = user => ({
  type: types.SET_USER,
  payload: user,
});

export const changeAuthStatus = bool => ({
  type: types.IS_AUTHENTICATED,
  payload: bool,
});

export const logout = () => ({ type: types.LOG_OUT });
