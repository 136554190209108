import reducer from "./reducers";
import * as actions from "./actions";
import sagas from "./sagas";
import * as selectors from "./selectors";
import * as types from "./types";

export const clientsActions = actions;
export const clientsSagas = sagas;
export const clientsReducer = reducer;
export const clientsSelectors = selectors;
export const clientsTypes = types;
