import React from "react";
import PropTypes from "prop-types";
import { Navbar, Nav, NavItem, NavLink } from "reactstrap";
import styles from "./LayoutHeader.module.scss";
// import logo from '../../../assets/images/black-logo.png';
import logo from "../../../assets/images/logo/main-logo.svg";
import logOutPng from "../../../assets/images/icons/logout.svg";
import PlayerButton from "./PlayerButton";

const LayoutHeader = ({
  isAuthenticated,
  logout,
  showNotification,
  accessCode,
}) => (
  <header className={styles.container}>
    <Navbar className={styles.navbar}>
      <div
        className={
          isAuthenticated ? styles.LogoContainerUserIn : styles.LogoContainer
        }
      >
        <a
          href="https://eventignite.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img alt="Digital Signage" src={logo} className={styles.imgSize} />
        </a>
      </div>
      {isAuthenticated && (
        <Nav className={styles.navbarStyle}>
          <NavItem className={styles.navItemMargin}>
            <NavLink onClick={logout}>
              <img
                alt="Logout"
                className={styles.imgLogoutPosition}
                src={logOutPng}
              />
            </NavLink>
          </NavItem>
        </Nav>
      )}
    </Navbar>
  </header>
);

LayoutHeader.propTypes = {
  logout: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  showNotification: PropTypes.func.isRequired,
  accessCode: PropTypes.string.isRequired,
};

export default LayoutHeader;
