// eslint-disable-next-line
export const urlRe = /^^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/; //(?:http(s)?:\/\/)
export const required = (value) => (value ? undefined : "Required");
export const isUrl = (value) =>
  value && !urlRe.test(value)
    ? "Please add URL in the format www.example.com"
    : undefined;
export const numberRegexp = (value) =>
  !value ? value : value.replace(/\D/g, "");
export const isSpaceChar = (value) =>
  value === " " ? "Remove space char" : undefined;
export const isTimeCorrect = (value) =>
  value.indexOf("_") !== -1 ? "Incorrect" : undefined;
export const normalizeExtraSpaces = (value) =>
  value && value.trimLeft().replace(/\s\s+/g, " ");
export const normalizeNumber = (value) =>
  value && value.trimLeft().replace(/\D/g, "");
export const isEmpty = (value) =>
  value && value.trim() === ""
    ? "Speaker name mustn’t contain spaces"
    : undefined;
export const isEmail = (value) =>
  new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}").test(value);
