import mixpanel from 'mixpanel-browser';

const isProduction = process.env.REACT_APP_BUILD === 'production';
const prodToken = '9f395984d11f048588e30b12d09c9539';
const stageToken = '68d11360d5315b6158b29fc8e0ed97f8';

mixpanel.init(isProduction ? prodToken : stageToken);

const actions = {
  identify: id => {
    mixpanel.identify(id);
  },
  alias: id => {
    mixpanel.alias(id);
  },
  track: (name, props) => {
    mixpanel.track(name, props);
  },
  people: {
    set: props => {
      mixpanel.people.set(props);
    },
  },
};

export default actions;
