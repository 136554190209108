import React from 'react';

export default function SvgIcoCloud() {
  return (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.1658 12.0828C26.0969 7.0515 21.9827 2.97949 16.9353 2.97949C14.6901 2.97949 12.527 3.79624 10.8446 5.2793C9.34311 6.60285 8.31598 8.37157 7.90922 10.3135C7.87951 10.3131 7.85005 10.3129 7.82066 10.3129C3.50843 10.313 0 13.8213 0 18.1336C0 22.4459 3.50843 25.9543 7.82073 25.9543H10.6911C11.0797 25.9543 11.3948 25.6392 11.3948 25.2505C11.3948 24.8618 11.0797 24.5468 10.6911 24.5468H7.82073C4.28445 24.5468 1.40752 21.6698 1.40752 18.1336C1.40752 14.5974 4.28445 11.7205 7.82073 11.7205C8.00912 11.7205 8.20828 11.7306 8.42968 11.7514C8.80492 11.7862 9.14182 11.5196 9.19293 11.1458C9.44636 9.28828 10.3635 7.57983 11.7754 6.33517C13.2005 5.07886 15.033 4.38702 16.9352 4.38702C21.2494 4.38702 24.7591 7.89681 24.7591 12.2109C24.7591 12.3662 24.7302 12.7679 24.7302 12.7679C24.7155 12.9749 24.793 13.1779 24.942 13.3225C25.0908 13.467 25.2957 13.5383 25.5026 13.5178C25.6897 13.4989 25.8786 13.4894 26.0638 13.4894C29.1123 13.4894 31.5925 15.9695 31.5925 19.018C31.5925 22.0665 29.1123 24.5466 26.0638 24.5466H22.9119C22.5234 24.5466 22.2082 24.8617 22.2082 25.2504C22.2082 25.6391 22.5234 25.9542 22.9119 25.9542H26.0638C29.8884 25.9542 33 22.8427 33 19.018C32.9999 15.2277 29.9436 12.1376 26.1658 12.0828Z"
        fill="black"
      />
      <path
        d="M16.9356 6.28725C13.9928 6.28725 11.4737 8.48645 11.0759 11.4027C11.0233 11.7878 11.2931 12.1414 11.6781 12.1951C12.0885 12.2524 12.4224 11.9458 12.4705 11.593C12.7736 9.37068 14.6931 7.69484 16.9356 7.69484C17.3243 7.69484 17.6393 7.3798 17.6393 6.99108C17.6393 6.6023 17.3242 6.28725 16.9356 6.28725Z"
        fill="black"
      />
      <path
        d="M20.192 26.444C19.9331 26.1542 19.4881 26.129 19.1982 26.3881L17.5054 27.9003V19.2423C17.5054 18.8536 17.1902 18.5385 16.8016 18.5385C16.4129 18.5385 16.0979 18.8536 16.0979 19.2423V27.9003L14.4051 26.388C14.1153 26.129 13.6702 26.1542 13.4114 26.444C13.1524 26.7339 13.1774 27.1787 13.4673 27.4377L16.0272 29.7247C16.248 29.922 16.5247 30.0205 16.8016 30.0205C17.0783 30.0205 17.3551 29.9219 17.5758 29.7248L20.1358 27.4378C20.4258 27.1789 20.4509 26.7339 20.192 26.444Z"
        fill="black"
      />
    </svg>
  );
}
