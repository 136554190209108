// eslint-disable

export default {
  editPencil: require("../../../../../assets/images/icons/edit_pencil.svg"),
  dynamicSlideButtonOrange: require("../../../../../assets/images/icons/code-orange.svg"),
  imageIconButtonOrange: require("../../../../../assets/images/icons/image-orange.svg"),
  dynamicSlideButton: require("../../../../../assets/images/icons/button-html-tag.svg"),
  videoButtonOrange: require("../../../../../assets/images/icons/video-player-orange.svg"),
  playlistIconGray: require("../../../../../assets/images/icons/left-menu-playlist-gray.svg"),
  monitorLiveBlue: require("../../../../../assets/images/icons/live-monitor-blue.svg"),
  monitorLiveGray: require("../../../../../assets/images/icons/live-monitor-gray.svg"),
  videoFrameWhite: require("../../../../../assets/images/icons/button-video-frame-white.svg"),
  windowsDownload: require("../../../../../assets/images/icons/windows-download.svg"),
  imageIconButton: require("../../../../../assets/images/icons/button-picture.svg"),
  screenIconGray: require("../../../../../assets/images/icons/left-menu-screens-gray.svg"),
  macOsDownload: require("../../../../../assets/images/icons/app-store-download.svg"),
  webLogoOrange: require("../../../../../assets/images/icons/web-logo-orange.svg"),
  playlistIcon: require("../../../../../assets/images/icons/left-menu-playlist.svg"),
  webLogoWhite: require("../../../../../assets/images/icons/web-logo-white.svg"),
  pictureWhite: require("../../../../../assets/images/icons/button-picture-white.svg"),
  webLogoGray: require("../../../../../assets/images/icons/web-logo-gray.svg"),
  deleteTrash: require("../../../../../assets/images/icons/delete-icon.svg"),
  deleteGray: require("../../../../../assets/images/icons/delete-icon-gray.svg"),
  videoButton: require("../../../../../assets/images/icons/button-video-frame.svg"),
  screenIcon: require("../../../../../assets/images/icons/left-menu-screens.svg"),
  close: require("../../../../../assets/images/icons/closeblue.svg"),
  closeDark: require("../../../../../assets/images/icons/close-dark.svg"),
  landscape: require("../../../../../assets/images/icons/orientation-landscape.svg"),
  calendarDark: require("../../../../../assets/images/icons/calendar-dark.svg"),
  plusWhite: require("../../../../../assets/images/icons/plus-white.svg"),
  plusDark: require("../../../../../assets/images/icons/plus-dark.svg"),
  editIcon: require("../../../../../assets/images/icons/edit-icon.svg"),
  editGray: require("../../../../../assets/images/icons/edit-gray.svg"),
  editDark: require("../../../../../assets/images/icons/edit-dark.svg"),
  portrait: require("../../../../../assets/images/icons/orientation-portrait.svg"),
  plusBlue: require("../../../../../assets/images/icons/blue-plus.svg"),
  display: require("../../../../../assets/images/icons/display.svg"),
  file: require("../../../../../assets/images/icons/file.svg"),
  clock: require("../../../../../assets/images/icons/clock.svg"),
  clockOrange: require("../../../../../assets/images/icons/clock-orange.svg"),
  clockWhite: require("../../../../../assets/images/icons/clock-white.svg"),
  clockDark: require("../../../../../assets/images/icons/clock-dark.svg"),
  dots: require("../../../../../assets/images/icons/dots.svg"),
  pencil: require("../../../../../assets/images/icons/pencil.svg"),
  arrowTopGray: require("../../../../../assets/images/icons/arrow-top-gray.svg"),
  maximize: require("../../../../../assets/images/icons/maximize.svg"),
  maximizeGray: require("../../../../../assets/images/icons/maximize-gray.svg"),
  minimize: require("../../../../../assets/images/icons/minimize.svg"),
  programmesIcon: require("../../../../../assets/images/icons/programmes-icon.svg"),
  programmesGrayIcon: require("../../../../../assets/images/icons/programmes-gray-icon.svg"),
  settings: require("../../../../../assets/images/icons/settings.svg"),
  playIcon: require("../../../../../assets/images/icons/play-icon.svg"),
  copyToClipboard: require("../../../../../assets/images/icons/copy-link-clipboard.svg"),
  openInNewTab: require("../../../../../assets/images/icons/open-new-tab.svg"),
  eyeOrange: require("../../../../../assets/images/icons/eye-icon.svg"),
  downloadOrange: require("../../../../../assets/images/icons/download-icon.svg"),
  clientBlack: require("../../../../../assets/images/icons/client-black.svg"),
  clientGray: require("../../../../../assets/images/icons/client-black.svg"),
  leftArrow: require("../../../../../assets/images/icons/left-arrow.svg"),
  rightArrow: require("../../../../../assets/images/icons/right-arrow.svg"),
  trashOrange: require("../../../../../assets/images/icons/trash.svg"),
  xCircleRed: require("../../../../../assets/images/icons/x-circle-red.svg"),
};
