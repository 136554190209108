import { noop } from 'redux-saga/utils';
import * as types from './types';
import { createReducer } from '../../utils';

export default createReducer({
  text: '',
  cancelText: '',
  confirmText: '',
  isOpen: false,
  onConfirm: noop,
})({
  [types.OPEN_CONFIRM_MODAL]: (state, { payload: { text, onConfirm, confirmText, cancelText } }) => ({
    ...state,
    text,
    cancelText,
    confirmText,
    isOpen: true,
    onConfirm,
  }),
  [types.CLOSE_CONFIRM_MODAL]: state => ({
    ...state,
    isOpen: false,
  }),
});
