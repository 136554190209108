import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { clientsActions } from "../../../../state/ducks/clients";
import ClientsView from "../components/ClientsView";
import { openConfirmModal } from "../../../../state/ducks/confirmModal/actions";

const {
  fetchCreateClient,
  fetchDeleteClient,
  fetchEditClient,
  fetchGetClients,
  setClientsCount,
  setCurrentPage,
} = clientsActions;

class ClientsViewContainer extends React.Component {
  render() {
    const {
      user,
      fetchCreateClient,
      fetchDeleteClient,
      openConfirmModal,
      fetchEditClient,
      isGettingClients,
      fetchGetClients,
      location: { pathname },
      clients,
      setClientsCount,
      setCurrentPage,
      clientsCount,
      currentPage,
    } = this.props;

    return (
      <ClientsView
        fetchCreateClient={fetchCreateClient}
        currentLocation={pathname}
        user={user}
        clients={clients}
        fetchDeleteClient={fetchDeleteClient}
        openConfirmModal={openConfirmModal}
        fetchEditClient={fetchEditClient}
        isGettingClients={isGettingClients}
        fetchGetClients={fetchGetClients}
        setClientsCount={setClientsCount}
        setCurrentPage={setCurrentPage}
        clientsCount={clientsCount}
        currentPage={currentPage}
      />
    );
  }
}

ClientsViewContainer.propTypes = {};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCreateClient: fetchCreateClient,
      fetchDeleteClient: fetchDeleteClient,
      openConfirmModal: openConfirmModal,
      fetchEditClient: fetchEditClient,
      fetchGetClients,
      setCurrentPage: setCurrentPage,
      setClientsCount: setClientsCount,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  user: state.auth.user,
  clients: state.clients.clients,
  isGettingClients: state.clients.isGettingClients,
  clientsCount: state.clients.clientsCount,
  currentPage: state.clients.currentPage,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientsViewContainer);
