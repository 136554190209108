import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import onClickOutside from 'react-onclickoutside';
import {
  getText,
  getConfirmText,
  getCancelText,
  getOnConfirm,
  getIsOpen,
} from '../../../../state/ducks/confirmModal/selectors';
import { confirmModalActions } from '../../../../state/ducks/confirmModal';

import styles from './ConfirmModal.module.scss';
import Button from '../UI/Button';

class ConfirmModal extends React.Component {
  handleClickOutside() {
    const { closeConfirmModal } = this.props;
    closeConfirmModal();
  }

  render() {
    const { isOpen, text, confirmText, cancelText, closeConfirmModal, onConfirm } = this.props;

    if (!isOpen) return null;
    return (
      <div className={styles.root}>
        <div className={styles.body}>
          <p className={styles.text}>{text}</p>
        </div>
        <div className={styles.footer}>
          {cancelText && (
            <Button theme="invert" onClick={closeConfirmModal}>
              {cancelText}
            </Button>
          )}
          {confirmText && (
            <Button
              onClick={() => {
                onConfirm();
                closeConfirmModal();
              }}
            >
              {confirmText}
            </Button>
          )}
        </div>
      </div>
    );
  }
}

ConfirmModal.propTypes = {
  text: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isOpen: PropTypes.string.isRequired,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  closeConfirmModal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  text: getText(state),
  confirmText: getConfirmText(state),
  cancelText: getCancelText(state),
  onConfirm: getOnConfirm(state),
  isOpen: getIsOpen(state),
});

export default connect(
  mapStateToProps,
  confirmModalActions,
)(onClickOutside(ConfirmModal));
