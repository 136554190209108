import _ from "lodash";
import { put, call, all, takeLatest, takeEvery } from "redux-saga/effects";

import * as types from "./types";
import { showNotification } from "../app/actions";
import { fetch, apiRoutes, getErrorMessage, sentryReport } from "../../utils";

import {
  setCreateClientStart,
  setCreateClientEnd,
  setDeleteClientStart,
  setDeleteClientEnd,
  setEditClientStart,
  setEditClientEnd,
  setGettingClientsStart,
  setGettingClientsEnd,
  setClients,
  setCurrentPage,
  setClientsCount,
} from "./actions";

function* fetchCreateClient(action) {
  try {
    yield put(setCreateClientStart());

    const { url, method } = apiRoutes.createClient;
    const { client } = action.payload;

    yield call(fetch, url(), method, client);

    yield* fetchGetClients();

    yield put(
      showNotification({
        level: "info",
        autoDismiss: 3,
        message: "The client is added.",
      })
    );

    yield put(setCreateClientEnd());
  } catch (error) {
    sentryReport(error);
    let messageError = getErrorMessage(error);

    const { level, message } = messageError;

    yield put(showNotification({ level, autoDismiss: 5, message }));
    yield put(setCreateClientEnd());
  }
}

function* fetchGetClients(action) {
  try {
    yield put(setGettingClientsStart());

    yield* fetchGetClientsCount();

    const { url, method } = apiRoutes.getClients;

    let offset = 0,
      limit = 12;

    if (action && action.payload) {
      offset = action.payload.offset || 0;
      limit = action.payload.limit || 12;
    }

    console.log(offset);
    console.log(limit);

    let clients = [];
    clients = yield call(fetch, url(offset, limit), method);
    console.log(clients);
    yield put(setClients(clients));

    yield put(setGettingClientsEnd());
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, autoDismiss: 5, message }));
    yield put(setGettingClientsEnd());
  }
}

function* fetchDeleteClient(action) {
  try {
    yield put(setDeleteClientStart());
    const { url, method } = apiRoutes.deleteClient;
    const { clientId } = action.payload;

    yield call(fetch, url(clientId), method);
    yield* fetchGetClients();

    yield put(
      showNotification({
        level: "info",
        autoDismiss: 3,
        message: "The client is deleted.",
      })
    );

    yield put(setDeleteClientEnd());
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, autoDismiss: 5, message }));
    yield put(setDeleteClientEnd());
  }
}

function* fetchEditClient(action) {
  try {
    yield put(setEditClientStart());
    const { url, method } = apiRoutes.updateClient;

    const { clientId: client } = action.payload;

    yield call(fetch, url(client.id), method, client);

    yield* fetchGetClients();

    yield put(
      showNotification({
        level: "info",
        autoDismiss: 3,
        message: "The client is updated.",
      })
    );

    yield put(setEditClientEnd());
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, autoDismiss: 5, message }));
    yield put(setEditClientEnd());
  }
}

function* fetchGetClientsCount() {
  try {
    const { url, method } = apiRoutes.getClientsCount;

    const { count } = yield call(fetch, url(), method);
    console.log("C: ");
    console.log(count);
    yield put(setClientsCount(count));
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, autoDismiss: 5, message }));
  }
}

export default [
  takeEvery(types.FETCH_CREATE_CLIENT, fetchCreateClient),
  takeEvery(types.FETCH_EDIT_CLIENT, fetchEditClient),
  takeEvery(types.FETCH_DELETE_CLIENT, fetchDeleteClient),
  takeLatest(types.FETCH_GET_CLIENTS, fetchGetClients),
  takeLatest(types.FETCH_GET_CLIENTS_COUNT, fetchGetClientsCount),
];
