// eslint-disable-next-line
const storage = localStorage;

export default {
  getToken: () => {
    const user = storage.getItem('DS_user');
    if (!user) return null;

    const { expiredAt, accessToken } = JSON.parse(user);
    if (expiredAt < +new Date()) {
      storage.removeItem('DS_user');
      return null;
    }

    return accessToken;
  },

  getUser: () => JSON.parse(storage.getItem('DS_user')),

  setToken: user => {
    const { ttl, id: accessToken, userId } = user;

    const expiredAt = +new Date(+new Date() + ttl * 1000);
    storage.setItem('DS_user', JSON.stringify({ accessToken, expiredAt, userId }));
  },

  clearToken: () => {
    storage.removeItem('DS_user');
  },
};
