const noop = () => null;

function safeGet(getFunc = noop, defaultValue = null) {
  try {
    return getFunc();
  } catch (e) {
    return defaultValue;
  }
}

export default safeGet;
