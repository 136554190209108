import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class SvgIcoOrientation extends Component {
  render() {
    const { setColor, orientation, iconSize } = this.props;
    const rotate = orientation && orientation === 'Portrait' ? { transform: 'rotate(90deg)' } : null;
    return (
      <svg
        style={rotate}
        width={!iconSize ? '40' : iconSize}
        height={!iconSize ? '40' : iconSize}
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.0001 8.99976H3.00012V30.9998H37.0001V8.99976ZM2.00012 7.99976V31.9998H38.0001V7.99976H2.00012Z"
          fill={setColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.50012 10.4998H9.00012V11.4998H5.50012V14.9998H4.50012V10.4998Z"
          fill={setColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.4999 29.5L30.9999 29.5L30.9999 28.5L34.4999 28.5L34.4999 25L35.4999 25L35.4999 29.5Z"
          fill={setColor}
        />
      </svg>
    );
  }
}

SvgIcoOrientation.propTypes = {
  setColor: PropTypes.string,
  orientation: PropTypes.string,
  iconSize: PropTypes.number,
};
