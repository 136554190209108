import reducer from './reducers';
import * as actions from './actions'; // actions
import sagas from './sagas'; // sagas
import * as selectors from './selectors';
import * as types from './types';

export const authActions = actions;
export const authSagas = sagas;
export const authSelectors = selectors;
export const authReducer = reducer;
export const authTypes = types;
