import React from 'react';
import PropTypes from 'prop-types';
import iconsMap from './iconsMap';

// Старый комоннет, который делал Олег, плохие пропсы
function Icons(props) {
  const iconPath = iconsMap[props.icon];

  return (
    <img
      src={iconPath}
      alt={iconPath}
      style={{
        width: `${props.widthIcons}px`,
        height: `${props.widthIcons}px`,
        marginRight: `${props.marginRight}px`,
        marginLeft: `${props.marginLeft}px`,
      }}
    />
  );
}

Icons.propTypes = {
  widthIcons: PropTypes.number,
  marginRight: PropTypes.number,
  marginLeft: PropTypes.number,
  icon: PropTypes.string.isRequired,
};

export default Icons;
