import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import LayoutView from "../LayoutView";
import { authActions } from "../../../state/ducks/auth";
import { appActions } from "../../../state/ducks/app";
import { fetchGetClients } from "../../../state/ducks/clients/actions";
import storage from "../../../state/utils/storage";

const { changeAuthStatus, logout, setUser } = authActions;
const { showNotification } = appActions;

class LayoutViewContainer extends React.Component {
  componentDidMount() {
    const token = storage.getToken();
    if (token) {
      this.props.changeAuthStatus(true);
      this.props.fetchGetClients();
    }
  }

  render() {
    return <LayoutView {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  fetching: state.auth.fetching,
  isAuthenticated: state.auth.isAuthenticated,
  notification: state.app.notification,
  showConfirmModal: state.app.showConfirmModal,
  user: state.auth.user,
  clients: state.clients.clients,
});

const mapDispatchToProps = {
  changeAuthStatus,
  logout,
  showNotification,
  fetchGetClients,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LayoutViewContainer)
);
