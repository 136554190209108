import React from 'react';
import Notifications from 'react-notification-system';
import PropTypes from 'prop-types';

class NotificationSystem extends React.Component {
  constructor(props) {
    super(props);
    this.notificationSystem = null;
  }

  componentDidUpdate() {
    const { notification, showNotification } = this.props;

    if (notification.level) {
      this.notificationSystem.clearNotifications();
      this.notificationSystem.addNotification(notification);
      showNotification({});
    }
  }

  render() {
    const style = {
      NotificationItem: {
        DefaultStyle: {
          display: 'inline-flex',
          margin: '80px 5px 2px 1px',
          position: 'fixed',
          maxWidth: 480,
          minWidth: 380,
          fontStyle: 'Regular',
          fontSize: '14px',
          lineHeight: '20px',
          paddingTop: '15px',
          textAlign: 'center',
          top: 0,
          bottom: 'auto',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: '10500000',
        },
        success: {
          color: 'white',
          backgroundColor: '#EC812B',
          border: 'none',
        },
        error: {
          color: 'white',
          backgroundColor: '#EB5757',
          border: 'none',
        },
        warning: {
          color: 'white',
          backgroundColor: '#ffdd1c',
          border: 'none',
        },
        info: {
          color: 'white',
          border: 'none',
          backgroundColor: '#EC812B',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          fontWeight: '200',
        },
      },
    };

    return <Notifications ref={ns => (this.notificationSystem = ns)} style={style} />;
  }
}

NotificationSystem.propTypes = {
  notification: PropTypes.object.isRequired,
  showNotification: PropTypes.func.isRequired,
};

export default NotificationSystem;
