import React from 'react';
import PropTypes from 'prop-types';
import styles from './OrientationCustomPort.module.scss';

export default function OrientationCustomPort({ isActive }) {
  const getFillColor = isActive ? styles.activeFillColor : styles.defaultFillColor;
  const getStrokeColor = isActive ? styles.activeStrokeColor : styles.defaultStrokeColor;
  return (
    <svg
      className={styles.mainSvg}
      width="24"
      height="60"
      viewBox="0 0 24 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect className={getStrokeColor} x="23.5" y="0.5" width="59" height="23" transform="rotate(90 23.5 0.5)" />
      <path
        className={getFillColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 57L3 52.5L4 52.5L4 56L7.5 56L7.5 57L3 57Z"
      />
      <path
        className={getFillColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 3L21 7.5L20 7.5L20 4L16.5 4L16.5 3L21 3Z"
      />
    </svg>
  );
}

OrientationCustomPort.propTypes = {
  isActive: PropTypes.bool.isRequired,
};
