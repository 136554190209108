// sync types
/*export const SET_SESSIONS = "sessions/SET_SESSIONS";
export const SET_SESSION_MODAL = "session/SET_SESSION_MODAL";
export const SESSION_REMOVE_START = "session/SESSION_REMOVE_START";
export const SESSION_REMOVE_END = "session/SESSION_REMOVE_END";
export const UPDATE_EXTERNAL_SESSIONS = "session/UPDATE_EXTERNAL_SESSIONS";*/

export const SET_CLIENTS = "clients/SET_CLIENTS";
export const CREATE_CLIENT_START = "clients/CREATE_CLIENT_START";
export const CREATE_CLIENT_END = "clients/CREATE_CLIENT_END";
export const DELETE_CLIENT_START = "clients/DELETE_CLIENT_START";
export const DELETE_CLIENT_END = "clients/DELETE_CLIENT_END";
export const EDIT_CLIENT_START = "clients/EDIT_CLIENT_START";
export const EDIT_CLIENT_END = "clients/EDIT_CLIENT_END";
export const GET_CLIENTS_START = "clients/GET_CLIENTS_START";
export const GET_CLIENTS_END = "clients/GET_CLIENTS_END";
export const SET_CURRENT_PAGE = "clients/SET_CURRENT_PAGE";
export const SET_CLIENTS_COUNT = "clients/SET_CLIENTS_COUNT";

// async types
export const FETCH_CREATE_CLIENT = "clients/FETCH_CREATE_CLIENT";
export const FETCH_EDIT_CLIENT = "clients/FETCH_UPDATE_CLIENT";
export const FETCH_GET_CLIENT = "clients/FETCH_GET_CLIENT";
export const FETCH_GET_CLIENTS = "clients/FETCH_GET_CLIENTS";
export const FETCH_DELETE_CLIENT = "clients/FETCH_DELETE_CLIENT";
export const FETCH_GET_CLIENTS_COUNT = "clients/FETCH_GET_CLIENTS_COUNT";
