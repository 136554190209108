import React, { useEffect } from "react";
import { Field, reduxForm, change } from "redux-form";
import PropTypes from "prop-types";
import styles from "./ClientCreateEmailForm.module.scss";
import { validation } from "../../../../../utils";
import EntityNameField from "../../../../common/components/UI/EntityNameField/EntityNameField";
import { bindActionCreators } from "redux";

function ClientCreateEmailForm({
  onChangeEmail,
  refEmail,
  defaultEmail,
  change,
  placeholder,
}) {
  useEffect(() => {
    if (defaultEmail) {
      change("email", defaultEmail);
    }
  }, [defaultEmail]);

  return (
    <form className={styles.theaterNameForm} autoComplete={"off"}>
      <Field
        ref={refEmail}
        withRef={true}
        name="email"
        type="text"
        isFocusAfterMounted={true}
        component={EntityNameField}
        className={styles.input}
        placeholder={placeholder ? placeholder : "Email"}
        validate={[validation.required, validation.isEmail]}
        onChange={onChangeEmail}
        normalize={validation.normalizeExtraSpaces}
      />
    </form>
  );
}

ClientCreateEmailForm.propTypes = {};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ change }, dispatch);
};

export default reduxForm(
  {
    form: "createClientEmail",
    /*enableReinitialize: true,
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: false,*/
  },
  mapDispatchToProps
)(ClientCreateEmailForm);
