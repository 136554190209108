import React from 'react';

export default function SvgIcoAppStore() {
  return (
    <svg width="30" height="28" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.878906 13.3709V10.0812C0.878906 9.77992 1.12645 9.53345 1.42968 9.53345H7.24278L4.71188 13.9194H1.42968C1.12645 13.9194 0.878906 13.6734 0.878906 13.3709ZM5.01979 20.107L3.16462 21.3639C2.915 21.5336 2.73847 21.4262 2.77308 21.1266L3.02062 18.9762C3.05506 18.6767 3.29798 18.5543 3.56068 18.704L4.9964 19.5242C5.25865 19.6746 5.26942 19.9379 5.01979 20.107ZM5.46528 18.8949L4.02956 18.0744C3.76708 17.9245 3.67459 17.5883 3.82728 17.3268L10.4754 5.80249C10.6263 5.54063 10.9662 5.44882 11.2286 5.60071L12.6643 6.41904C12.9269 6.57093 13.017 6.90742 12.8666 7.16832L6.2185 18.6935C6.0679 18.9549 5.72765 19.0446 5.46528 18.8949ZM10.525 13.9194L13.0559 9.53345H14.5132L16.5683 13.9194H10.525ZM18.768 15.2282C18.1932 14.9412 15.3308 8.36602 14.5974 6.65046C13.8632 4.93485 11.6464 0.368203 12.3635 0.012839C12.8735 -0.241919 14.7049 3.35405 16.0779 5.5129C17.4488 7.67186 20.3157 13.2384 20.6685 13.8723C21.0195 14.5078 20.3308 15.0376 19.9478 15.1962C19.5644 15.3548 19.3425 15.5129 18.768 15.2282ZM20.649 18.2505L19.7841 16.8903C19.6052 16.6072 19.6981 16.2579 19.9882 16.1087L20.8126 15.6866C21.1033 15.5387 21.4671 15.6587 21.6218 15.9546L22.304 17.2612C22.4593 17.5566 22.3535 17.9359 22.0736 18.1029L21.4841 18.4539C21.2043 18.6229 20.8278 18.5309 20.649 18.2505ZM23.8321 21.8184C23.6514 21.2421 22.2717 21.2039 21.6541 20.3361C21.0344 19.4712 21.5465 19.0256 21.7617 18.8199C24.267 17.415 23.8321 21.8184 23.8321 21.8184ZM25.1213 13.3709C25.1213 13.6734 24.8738 13.9193 24.5705 13.9193H21.6607C21.6286 13.7502 21.5766 13.5811 21.4886 13.4225C21.4129 13.2875 21.2214 12.9234 20.9547 12.416C20.5695 11.6837 20.0164 10.6364 19.4243 9.53345H24.5702C24.8736 9.53345 25.1211 9.77992 25.1211 10.0812V13.3709H25.1213Z"
        fill="black"
      />
    </svg>
  );
}
