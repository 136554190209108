import React from 'react';
import PropTypes from 'prop-types';
import styles from './OrientationSquare.module.scss';

export default function OrientationSquare({ isActive }) {
  const getFillColor = isActive ? styles.activeFillColor : styles.defaultFillColor;
  const getStrokeColor = isActive ? styles.activeStrokeColor : styles.defaultStrokeColor;
  return (
    <svg
      className={styles.mainSvg}
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect className={getStrokeColor} x="0.5" y="1.27423" width="29" height="29" />
      <path
        className={getFillColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.25 27.0242L20.625 27.0242L20.625 25.7742L25 25.7742L25 21.3992L26.25 21.3992L26.25 27.0242Z"
      />
      <path
        className={getFillColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 4.52423H9.375V5.77423H5V10.1492H3.75V4.52423Z"
      />
    </svg>
  );
}

OrientationSquare.propTypes = {
  isActive: PropTypes.bool.isRequired,
};
