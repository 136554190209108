import * as Sentry from '@sentry/browser';

const isProduction = process.env.REACT_APP_BUILD === 'production';
const prodDsn = 'https://82b84bd61003488993adddd2d7df939e@sentry.io/1483651';
const stageDsn = 'https://cbea9afddd14414a9bb8980d80c6719f@sentry.io/1483646';

Sentry.init({
  dsn: isProduction ? prodDsn : stageDsn,
});

export default function sentryReport(error) {
  Sentry.captureException(error);
}
