import * as types from "./types";

// sync

// async

export const showNotification = (notification) => ({
  type: types.SHOW_NOTIFICATION,
  payload: notification,
});
