import { type } from "jquery";
import * as types from "./types";

// sync

export const setClients = (clients) => ({
  type: types.SET_CLIENTS,
  payload: clients,
});

export const setCreateClientStart = () => ({
  type: types.CREATE_CLIENT_START,
});

export const setCreateClientEnd = () => ({
  type: types.CREATE_CLIENT_END,
});

export const setDeleteClientStart = () => ({
  type: types.DELETE_CLIENT_START,
});

export const setDeleteClientEnd = () => ({
  type: types.DELETE_CLIENT_END,
});

export const setEditClientStart = () => ({
  type: types.EDIT_CLIENT_START,
});

export const setEditClientEnd = () => ({
  type: types.EDIT_CLIENT_END,
});

export const setGettingClientsStart = () => ({
  type: types.GET_CLIENTS_START,
});

export const setGettingClientsEnd = () => ({
  type: types.GET_CLIENTS_END,
});

export const setCurrentPage = (currentPage) => ({
  type: types.SET_CURRENT_PAGE,
  payload: currentPage,
});

export const setClientsCount = (clientsCount) => ({
  type: types.SET_CLIENTS_COUNT,
  payload: clientsCount,
});

// async

export const fetchCreateClient = (client) => ({
  type: types.FETCH_CREATE_CLIENT,
  payload: { client },
});

export const fetchEditClient = (clientId, client) => ({
  type: types.FETCH_EDIT_CLIENT,
  payload: { clientId, client },
});

export const fetchGetClient = (clientId) => ({
  type: types.FETCH_GET_CLIENT,
  payload: { clientId },
});

export const fetchGetClients = (offset, limit) => ({
  type: types.FETCH_GET_CLIENTS,
  payload: { offset, limit },
});

export const fetchDeleteClient = (clientId) => ({
  type: types.FETCH_DELETE_CLIENT,
  payload: { clientId },
});

export const fetchGetClientsCount = () => ({
  type: types.FETCH_GET_CLIENTS_COUNT,
});
