import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '../UI/Button';
// import Link from '@UI/Link';

import styles from './ErrorBoundary.module.scss';

class ErrorBoundary extends Component {
  state = {
    hasError: false,
  };

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    // eslint-disable-next-line
    console.log(info);
  }

  handleReloadApp = () => window.location.reload(true);

  renderErrorDialog = () => (
    <div className={styles.root}>
      <div className={styles.modal}>
        <h2 className={styles.text}>The application encountered an error</h2>
        <Button onClick={this.handleReloadApp}>Restart App</Button>
      </div>
    </div>
  );

  // TODO: implement error catching
  render() {
    if (this.state.hasError) {
      return this.renderErrorDialog();
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any,
};

export default ErrorBoundary;
