import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
// import PropTypes from 'prop-types';
import styles from './UploadSpinner.module.scss';
import spinner from '../../../../../assets/gif/spinner.gif';

const UploadSpinner = ({ className, underlayClassName, zIndex }) => (
  <div className={cn(styles.container, className && className)} style={{ zIndex }}>
    <div className={cn(styles.opacityBack, underlayClassName && underlayClassName)} />
    <img
      style={{ height: '100%', maxHeight: '200px' }}
      className={styles.spinnerStyle}
      src={spinner}
      alt="loading..."
    />
  </div>
);

UploadSpinner.defaultProps = {
  zIndex: 0,
};

UploadSpinner.propTypes = {
  className: PropTypes.string,
  underlayClassName: PropTypes.string,
  zIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default UploadSpinner;
