import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import styles from "./ClientsList.module.scss";
import Pagination from "../../../../common/components/UI/Pagination";
import { Link } from "react-router-dom";
import Icons from "../../../../common/components/UI/Icons";

function ClientsList({
  //clientsToRender,
  clients,
  recordsPerPage,
  currentPage,
  setCurrentPage,
  fetchDeleteClient,
  openConfirmModal,
  isGettingClients,
  currentLocation,
  clientsCount,
}) {
  const [editingId, setEditingId] = useState();
  const [isNewClientBeingAdded, setIsNewClientBeingAdded] = useState(false);

  useEffect(() => {
    if (currentLocation) {
      let id = getEditingClientId(currentLocation);

      setEditingId(id);

      const isNew = currentLocation.split("/")[2] == "new";
      setIsNewClientBeingAdded(isNew);
    }
  }, [currentLocation]);

  const handleDeleteClient = ({ email, id }) => {
    openConfirmModal({
      text:
        "Are you sure you want to delete the client with email " + email + "?",
      onConfirm: () => {
        fetchDeleteClient(id);
      },
      confirmText: "Yes",
      cancelText: "No",
    });
  };

  const getEditingClientId = (currentLocation) => {
    const id = currentLocation.split("/")[3];
    const isEditing = currentLocation.split("/")[2] == "edit";

    if (isEditing) return id;
  };

  return (
    <div
      className={
        styles.outerClientsContainer +
        " " +
        (editingId || isNewClientBeingAdded
          ? styles.outerClientsContainer__onEdit
          : null)
      }
    >
      <div className={styles.clientsContainer}>
        <div className={styles.buttonsContainer}>
          <Link to={`/clients/new`} className={styles.button}>
            <Icons icon="plusWhite" widthIcons={24} marginRight={5} />
            Add New Client
          </Link>
        </div>
        <table
          className={styles.clientsTable}
          style={{ height: `${40 * recordsPerPage}px` }}
        >
          <thead>
            <th>ID</th>
            <th>EMAIL</th>
            <th>USERNAME</th>
            <th>ACCESS CODE</th>
          </thead>

          <tbody className={styles.clientsTableBody}>
            {clients.map((client, index) => (
              <tr className={index % 2 == 0 ? styles.evenRow : styles.oddRow}>
                <td>{client.id}</td>
                <td>{client.email}</td>
                <td>{client.username}</td>
                <td>{client.accessCode}</td>
                <div className={styles.tableIconsContainer}>
                  <span
                    className={
                      (index % 2 == 0 ? styles.evenRow : styles.oddRow) +
                      " " +
                      styles.tableIcon
                    }
                  >
                    <button
                      style={{ background: "none" }}
                      onClick={() => {
                        handleDeleteClient({
                          email: client.email,
                          id: client.id,
                        });
                      }}
                    >
                      <Icons icon="trashOrange" widthIcons={22} />
                    </button>
                  </span>
                  <span
                    className={
                      (index % 2 == 0 ? styles.evenRow : styles.oddRow) +
                      " " +
                      styles.tableIcon
                    }
                  >
                    <Link to={`clients/edit/${client.id}`}>
                      <Icons icon="editPencil" widthIcons={22} />
                    </Link>
                  </span>
                </div>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className={styles.PaginationContainer}>
        <Pagination
          numberOfPages={Math.ceil(clientsCount / recordsPerPage)}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
}

ClientsList.propTypes = {};

export default withRouter(ClientsList);
