import React, { Component } from "react";
import styles from "./ClientsEditView.module.scss";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { getFormValues } from "redux-form";
import { connect } from "react-redux";

import ButtonDigital from "../../../../common/components/UI/ButtonDigital";
import onClickOutside from "react-onclickoutside";
import { history } from "../../../../../state/utils";
import Icons from "../../../../common/components/UI/Icons";

import ClientCreateEmailForm from "../ClientCreateEmailForm/";
import ClientCreateAccessCodeForm from "../ClientCreateAccessCodeForm";
import ClientCreateUsernameForm from "../ClientCreateUsernameForm";
import ClientCreatePasswordForm from "../ClientCreatePasswordForm";

class ClientsEditView extends Component {
  constructor(props) {
    super(props);
    this.refEmail = React.createRef();
    this.refUsername = React.createRef();
    this.refPassword = React.createRef();
    this.refAccessCode = React.createRef();
  }

  state = {
    isChangeEmail: false,
    isChangeUsername: false,
    isChangePassword: false,
    isChangeAccessCode: false,
  };

  handleClickOutside() {
    history.push(`${process.env.PUBLIC_URL}/clients`);
  }

  /*handleChangeName = (event) => {
    const { value } = event.target;
    const { handleCreateNewTheatre } = this.props;

    const removeExtraSpaces = value.replace(/\s+/g, " ").trim();
    if (removeExtraSpaces.length !== 0) {
      handleCreateNewTheatre(removeExtraSpaces);
      this.refTitleName.current.getRenderedComponent().inputRef.current.blur();
    }
  };*/

  render() {
    const fetchEditClient = this.props.fetchEditClient;

    const id = this.props.currentLocation.split("/")[3];
    const client = this.props.clients.find((client) => client.id == id);

    return (
      <div className={styles.container}>
        <span className={styles.title}>UPDATING EXISTING CLIENT</span>
        <Link to="/clients" className={styles.closeButton} />

        <ClientCreateEmailForm
          //placeholder={client ? client.email : null}
          defaultEmail={client ? client.email : null}
          onChangeEmail={() => {
            this.setState((state) => ({ ...state, isChangeEmail: true }));
          }}
          refEmail={this.refEmail}
        />
        <ClientCreateUsernameForm
          //placeholder={client ? client.username : null}
          defaultUsername={client ? client.username : null}
          onChangeUsername={() => {
            this.setState((state) => ({ ...state, isChangeUsername: true }));
          }}
          refUsername={this.refUsername}
        />
        <ClientCreateAccessCodeForm
          //placeholder={client ? client.accessCode : "Access Code Not Set"}
          defaultAccessCode={client ? client.accessCode : null}
          onChangeAccessCode={() => {
            this.setState((state) => ({ ...state, isChangeAccessCode: true }));
          }}
          refAccessCode={this.refAccessCode}
        />
        <ClientCreatePasswordForm
          placeholder={
            "Edit Password (leave blank if you don't want to change the password)"
          }
          //isPasswordOptional={true}
          onChangePassword={() => {
            this.setState((state) => ({ ...state, isChangePassword: true }));
          }}
          refPassword={this.refPassword}
        />

        <div className={styles.buttonsContainer}>
          <ButtonDigital
            className={styles.addClientButton}
            /*disable={
            !(
              validation.isEmail(this.props.emailInput) &&
              this.state.isChangePassword
            )
          }*/
            isConfirmStyle={true}
            type="button"
            onClick={() => {
              let constructEditClient = {};
              constructEditClient["id"] = id;
              if (this.props.passwordInput)
                constructEditClient["password"] = this.props.passwordInput;
              if (this.props.accessCodeInput)
                constructEditClient["accessCode"] = this.props.accessCodeInput;
              if (this.props.usernameInput)
                constructEditClient["username"] = this.props.usernameInput;
              if (this.props.emailInput)
                constructEditClient["email"] = this.props.emailInput;

              fetchEditClient(constructEditClient);

              history.push(`${process.env.PUBLIC_URL}/clients`);
            }}
          >
            Update Client
          </ButtonDigital>
          <Link to={`/clients`} className={styles.cancelButton}>
            <Icons icon="xCircleRed" widthIcons={26} marginRight={5} />
            Cancel
          </Link>
        </div>
      </div>
    );
  }
}

ClientsEditView.propTypes = {};

const mapStateToProps = (state) => {
  const { username } = getFormValues("createClientUsername")(state) || {};
  const { email } = getFormValues("createClientEmail")(state) || {};
  const { password } = getFormValues("createClientPassword")(state) || {};
  const { accessCode } = getFormValues("createClientAccessCode")(state) || {};
  return {
    usernameInput: username,
    emailInput: email,
    passwordInput: password,
    accessCodeInput: accessCode,
  };
};

export default connect(mapStateToProps)(onClickOutside(ClientsEditView));
