import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class SvgIcoNotDefault extends Component {
  render() {
    const { setColor, orientation, iconSize } = this.props;
    const rotate = orientation ? { transform: 'rotate(90deg)' } : null;

    return (
      <svg
        style={rotate}
        width={rotate ? iconSize || '50' : iconSize || '64'}
        height={rotate ? iconSize || '64' : iconSize || '40'}
        viewBox="0 0 64 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="2.5" y="8.5" width="59" height="23" stroke={setColor} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M59 29L54.5 29L54.5 28L58 28L58 24.5L59 24.5L59 29Z"
          fill={setColor}
        />
        <path fillRule="evenodd" clipRule="evenodd" d="M5 11H9.5V12H6V15.5H5V11Z" fill={setColor} />
      </svg>
    );
  }
}

SvgIcoNotDefault.propTypes = {
  setColor: PropTypes.string,
  orientation: PropTypes.bool,
  iconSize: PropTypes.number,
};
