import React from 'react';
import cn from 'classnames';
import styles from './ButtonDigital.module.scss';

export default function ButtonDigital({ btnRef, onClick, children, isConfirmStyle, type, disable = false, className }) {
  return (
    <button
      ref={btnRef}
      style={disable ? { background: '#afafaf', cursor: 'not-allowed' } : null}
      type={type}
      className={cn(isConfirmStyle && styles.confirm, !isConfirmStyle && styles.decline, className && className)}
      onClick={onClick}
      disabled={disable}
    >
      {children}
    </button>
  );
}
